import { Suspense, lazy, useEffect } from 'react';
import { useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { PageViewTracker } from './components';
import { useAuth } from './hooks';
import { useShallow } from 'zustand/react/shallow';
import { appRouter } from './navigation';
import useProfileStore from './stores/ProfileStore';
import useStudentStore from './stores/StudentStore';
import SuccessSnackbar from './components/shared/SuccessSnackbar';
const AppContainer = lazy(() => import('./containers/AppContainer'));

const RouterWrapper = () => {
  const { user, isLoading } = useAuth();
  const { profile, setSnackBarStatus, snackBarStatus } = useProfileStore(
    useShallow((state) => ({
      profile: state.profile,
      setSnackBarStatus: state.setSnackBarStatus,
      snackBarStatus: state.snackBarStatus,
    }))
  );
  const [profileData, setProfileData] = useState(null);
  const { student, setSelectedStudent } = useStudentStore(
    useShallow((state) => ({
      student: state.student,
      setSelectedStudent: state.setSelectedStudent,
    }))
  );
  const [loggedInUser, setLoggedInUser] = useState(null);
  const isOneStudentExists = student.length === 1;

  useEffect(() => {
    if (profile && Object.keys(profile).length > 0) {
      setProfileData(profile);
    } else {
      setProfileData(null);
    }
    // if (isOneStudentExists) {
    //   setSelectedStudent(student.flat()[0]);
    // }
    //eslint-disable-next-line
  }, [profile, isOneStudentExists]);

  useEffect(() => {
    setLoggedInUser(profile || user);
    //eslint-disable-next-line
  }, [profile, user]);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <AppContainer />
      </Suspense>

      <RouterProvider
        router={appRouter(
          profileData,
          loggedInUser,
          isOneStudentExists,
          isLoading
        )}
      >
        <PageViewTracker />
      </RouterProvider>
      <SuccessSnackbar
        autoHideDuration={null}
        open={snackBarStatus.open}
        message={snackBarStatus.message}
        onAction={() => {
          setSnackBarStatus({ open: false, message: '' });
        }}
        actionName={'OK'}
      />
    </>
  );
};

export default RouterWrapper;
