import { db, auth } from '../config';
import {
  collection,
  query,
  where,
  getDocs,
  getCountFromServer,
} from 'firebase/firestore';
import { getLocalUser } from '../../utils/localStorage';
import { localKeys } from '../../constants/localStorage';
import {
  APPLICATION_TEMPLATES,
  CHAT_MESSAGES,
  CHAT_ROOMS,
  COLLEGES_LIST,
  NICHE,
  PROFILE,
  REFERRAL,
  REVIEWER,
} from '../constants';

const user = getLocalUser(localKeys.AUTH);

// GET NICHE
export const getNiche = (nicheId) =>
  new Promise((resolve) => {
    const getNicheQuery = query(
      collection(db, NICHE),
      where('createdBy', '==', auth?.currentUser?.uid || user?.uid),
      where('id', '==', nicheId),
      where('deleteDocument', '==', false)
    );
    let fetchedNiches = [];
    getDocs(getNicheQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedNiches.push(fetchedNiche);
        });
        resolve(fetchedNiches[0]);
      })
      .catch((error) => resolve(false));
  });

export const getMessagesForChatRoom = async (chatRoomId, userId, readValue) => {
  let chatMessages = [];
  const messageQuery = query(
    collection(db, CHAT_ROOMS, chatRoomId, CHAT_MESSAGES),
    where('isRead', '==', readValue),
    where('senderId', '!=', userId)
  );
  await getDocs(messageQuery).then((querySnapshot) => {
    const newObj = querySnapshot.docs.map((doc) => doc.data());
    chatMessages = newObj;
  });

  return chatMessages;
};

export const getMessagesForChat = async (chatRoomId) => {
  let chatMessages = [];
  const messageQuery = query(
    collection(db, CHAT_ROOMS, chatRoomId, CHAT_MESSAGES)
  );
  getDocs(messageQuery).then((querySnapshot) => {
    const newObj = querySnapshot.docs.map((doc) => doc.data());
    chatMessages = newObj;
  });

  return chatMessages;
};

export const getUnReadMessageCount = async (chatRoomId, userId) => {
  if (chatRoomId && userId) {
    const messageQuery = query(
      collection(db, CHAT_ROOMS, chatRoomId, CHAT_MESSAGES),
      where('isRead', '==', false),
      where('senderId', '!=', userId)
    );
    return await getCountFromServer(messageQuery)
      .then((result) => result.data().count)
      .then((result) => {
        return result;
      });
  } else return 0;
};

export const getReferralData = (emailId) => {
  return new Promise((resolve) => {
    const getReferralQuery = query(
      collection(db, REFERRAL),
      where('email', '==', emailId),
      where('deleteDocument', '==', false)
    );
    let fetchedNiches = [];
    getDocs(getReferralQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedNiches.push(fetchedNiche);
        });
        resolve(fetchedNiches[0]);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

export const getReveiewerDetails = (emailId, createdBy) => {
  return new Promise((resolve) => {
    const getReviewerQuery = query(
      collection(db, REVIEWER),
      where('email', '==', emailId),
      where('createdBy', '==', createdBy),
      where('deleteDocument', '==', false)
    );
    let fetchedReviewer = [];
    getDocs(getReviewerQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedReviewer.push(fetchedNiche);
        });
        resolve(fetchedReviewer[0]);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

export const getReveiewerTeamDetails = (emailId, createdBy) => {
  return new Promise((resolve) => {
    const getReviewerQuery = query(
      collection(db, REVIEWER),
      where('studentEmail', '==', emailId),
      where('reviewerId', '==', createdBy),
      where('deleteDocument', '==', false)
    );
    let fetchedReviewer = [];
    getDocs(getReviewerQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedReviewer.push(fetchedNiche);
        });
        resolve(fetchedReviewer[0]);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};
export const getProfileDetails = (emailId) => {
  return new Promise((resolve) => {
    const getReviewerQuery = query(
      collection(db, PROFILE),
      where('email', '==', emailId),
      where('deleteDocument', '==', false)
    );
    let fetchedReviewer = [];
    getDocs(getReviewerQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedReviewer.push(fetchedNiche);
        });
        resolve(fetchedReviewer[0]);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};
export const getActiveStudentReviewers = (activeStudentUID) => {
  return new Promise((resolve) => {
    const getReviewerQuery = query(
      collection(db, REVIEWER),
      where('revieweeId', '==', activeStudentUID),
      where('deleteDocument', '==', false)
    );
    let fetchedReviewer = [];
    getDocs(getReviewerQuery)
      .then(async (response) => {
        for (let index = 0; index < response.docs.length; index++) {
          const document = response.docs[index];
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          // Wait for each getProfileDetails call to finish before proceeding
          const result = await getProfileDetails(fetchedNiche.email);
          fetchedReviewer.push({ reviewerDocId: document.id, ...result });
        }
        resolve(fetchedReviewer);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

export const getLoggedInReviewerId = (loggedInParentEmail, activeStudentId) => {
  return new Promise((resolve) => {
    const getReviewerQuery = query(
      collection(db, REVIEWER),
      where('email', '==', loggedInParentEmail),
      where('revieweeId', '==', activeStudentId),
      where('deleteDocument', '==', false)
    );
    let fetchedReviewer = [];
    getDocs(getReviewerQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedNiche = {
            id: document.id,
            ...document.data(),
          };
          fetchedReviewer.push(fetchedNiche);
        });
        resolve(fetchedReviewer[0]);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

// Get Colleges List
export const getCollegesList = (isAll = false) =>
  new Promise((resolve) => {
    const getCollegesQuery = isAll
      ? query(collection(db, COLLEGES_LIST))
      : query(
          collection(db, COLLEGES_LIST),
          where('deleteDocument', '==', false)
        );
    let fetchedColleges = [];
    getDocs(getCollegesQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedCollege = {
            id: document.id,
            ...document.data(),
          };
          fetchedColleges.push(fetchedCollege);
        });
        resolve(
          fetchedColleges?.sort((a, b) =>
            a?.collegeName?.localeCompare(b?.collegeName)
          )
        );
      })
      .catch(() => resolve(fetchedColleges));
  });

export const fetchApplicationRequirements = (userId, isAll = false) =>
  new Promise((resolve) => {
    const getQuery = isAll
      ? query(collection(db, APPLICATION_TEMPLATES))
      : query(
          collection(db, APPLICATION_TEMPLATES),
          where('deleteDocument', '==', false)
        );
    let fetchedRequirements = [];
    getDocs(getQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedRequirement = {
            id: document.id,
            ...document.data(),
          };
          fetchedRequirements.push(fetchedRequirement);
        });
        resolve(fetchedRequirements);
      })
      .catch((error) => {
        resolve(fetchedRequirements);
      });
  });

export const getStoryItem = (collectionName, queryList = null) =>
  new Promise((resolve) => {
    const dbQuery = queryList
      ? query(
          collection(db, collectionName),
          ...queryList.map(({ property, operator, value }) =>
            where(property, operator, value)
          )
        )
      : query(collection(db, collectionName));
    let fetchedData = [];
    getDocs(dbQuery)
      .then((response) => {
        response.docs.forEach((document) => {
          const fetchedDoc = {
            id: document.id,
            ...document.data(),
          };
          fetchedData.push(fetchedDoc);
        });
        resolve(fetchedData);
      })
      .catch(() => {
        resolve('');
      });
  });
