import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import useProfileStore from '../stores/ProfileStore';
import { checkForPremiumAccess } from '../utils/checkForPremiumAccess';
import useStudentStore from '../stores/StudentStore';

export const LicenseGuard = ({ children }) => {
  const profile = useProfileStore((state) => state.profile);
  const isParent = useProfileStore((state) => state.isParent);
  const selectedStudent = useStudentStore((state) => state.selectedStudent);
  const subscriptionStatus = isParent
    ? selectedStudent?.licenseData?.subscriptionStatus
    : profile?.licenseData?.subscriptionStatus;
  if (!checkForPremiumAccess(subscriptionStatus)) {
    return (
      <Navigate to={isParent ? '/myStudents' : '/myAccount/subscriptions'} />
    );
  }
  return <>{children}</>;
};
