import { createBrowserRouter, Navigate } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import * as ROUTES from './constants';
import { LicenseGuard } from '../guards/LicenseGuard';

const AuthGuard = lazy(() => import('../guards/AuthGuard'));
const GuestGuard = lazy(() => import('../guards/GuestGuard'));
const ErrorPage = lazy(() => import('../pages/error-page'));
const SignInPage = lazy(() => import('../pages/auth/signIn/index'));
const SignUpPage = lazy(() => import('../pages/auth/signUp/index'));
const AcceptInvitation = lazy(() =>
  import('../pages/accept-invitation/AcceptInvitation')
);
const ForgotPassword = lazy(() =>
  import('../pages/forgot-password/ForgotPassword')
);
const DashBoardLayout = lazy(() => import('../layouts/dashboardLayout/index'));
const DashBoardPage = lazy(() => import('../pages/dashboard/index'));
const MyStoryContainer = lazy(() => import('../containers/MyStory.container'));
const MyStoryDayZero = lazy(() =>
  import('../components/myStory/MyStoryDayZero')
);
const ActivitiesPageContainer = lazy(() =>
  import('../containers/ActivitiesPage.container')
);
const HonorsPageContainer = lazy(() =>
  import('../containers/HonorsPage.container')
);
const NichePageContainer = lazy(() =>
  import('../containers/NichePage.container')
);
const TestsPageContainer = lazy(() =>
  import('../containers/TestsPage.container')
);
const GPAPageContainer = lazy(() => import('../containers/GPAPage.container'));
const CoursesPageContainer = lazy(() =>
  import('../containers/CoursesPage.container')
);
const AllActivitiesPageContainer = lazy(() =>
  import('../containers/AllActivitiesPage.container')
);
const MyHomePageContainer = lazy(() =>
  import('../containers/MyHomePage.container')
);
const AllHonorsPageContainer = lazy(() =>
  import('../containers/AllHonorsPage.container')
);
const MyEssayPageContainer = lazy(() =>
  import('../containers/MyEssayPage.container')
);

const ReferAFriendPageContainer = lazy(() =>
  import('../containers/ReferAFriendPage.container')
);
const MyTeamPageContainer = lazy(() =>
  import('../containers/MyTeamPage.container')
);
const MyStudentPageContainer = lazy(() =>
  import('../containers/MyStudentPage.container')
);
const MyHubContainer = lazy(() => import('../containers/MyHub.container'));
const UserLoginDetailsContainer = lazy(() =>
  import('../containers/UserLoginDetails.container')
);
const CollegeDetailsPageContainer = lazy(() =>
  import('../containers/CollegeDetailsPage.container')
);
const UniversilyTemplatesContainer = lazy(() =>
  import('../containers/UniversilyTemplates.container')
);
const CommonEssayContainer = lazy(() =>
  import('../containers/CommonEssay.container')
);
const MyAccountPageContainer = lazy(() =>
  import('../containers/MyAccountPage.container')
);
const CustomActionURLContainer = lazy(() =>
  import('../containers/CustomActionURLContainer')
);
const MyApplicationsPageContainer = lazy(() =>
  import('../containers/MyApplicationsPage.container')
);
export const appRouter = (profileData, user, isOneStudentExists, isLoading) => {
  return createBrowserRouter([
    {
      path: ROUTES.SIGN_IN,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <GuestGuard
            profileData={profileData}
            user={user}
            isOneStudentExists={isOneStudentExists}
            isLoading={isLoading}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <SignInPage />
            </Suspense>
          </GuestGuard>
        </Suspense>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: ROUTES.SIGN_UP,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <GuestGuard
            profileData={profileData}
            user={user}
            isOneStudentExists={isOneStudentExists}
            isLoading={isLoading}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <SignUpPage />
            </Suspense>
          </GuestGuard>
        </Suspense>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: ROUTES.REVIEWER_SIGN_UP,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <GuestGuard
            profileData={profileData}
            user={user}
            isOneStudentExists={isOneStudentExists}
            isLoading={isLoading}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <SignUpPage />
            </Suspense>
          </GuestGuard>
        </Suspense>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: ROUTES.ACCEPT_TEAM_INVITE,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AcceptInvitation />
        </Suspense>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: ROUTES.FORGOT_PASSWORD,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <ForgotPassword />
        </Suspense>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: ROUTES.ROOT,
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <AuthGuard
            profileData={profileData}
            user={user}
            isOneStudentExists={isOneStudentExists}
            isLoading={isLoading}
          ></AuthGuard>
        </Suspense>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          element: (
            <Suspense fallback={<div>Loading...</div>}>
              <DashBoardLayout />
            </Suspense>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <DashBoardPage />
                </Suspense>
              ),
            },
            {
              path: 'myStory',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <LicenseGuard>
                    <MyStoryContainer />
                  </LicenseGuard>
                </Suspense>
              ),
              handle: {
                crumb: 'My Story',
              },
              children: [
                {
                  index: true,
                  element: (
                    <Suspense fallback={<div>Loading...</div>}>
                      <MyStoryDayZero
                        showDropDown={true}
                        text="Niches, Activities, Honors, Courses and Tests"
                      />
                    </Suspense>
                  ),
                },
                {
                  path: 'activities',
                  element: (
                    <Suspense fallback={<div>Loading...</div>}>
                      <ActivitiesPageContainer />
                    </Suspense>
                  ),
                  // handle: {
                  //   crumb: 'Activities',
                  // },
                },
                {
                  path: 'honors',
                  element: (
                    <Suspense fallback={<div>Loading...</div>}>
                      <HonorsPageContainer />
                    </Suspense>
                  ),
                  // handle: {
                  //   crumb: 'Honors',
                  // },
                },
                {
                  path: 'niche',
                  element: (
                    <Suspense fallback={<div>Loading...</div>}>
                      <NichePageContainer />
                    </Suspense>
                  ),
                  // handle: {
                  //   crumb: 'Niche',
                  // },
                },
                {
                  path: 'tests',
                  element: (
                    <Suspense fallback={<div>Loading...</div>}>
                      <TestsPageContainer />
                    </Suspense>
                  ),
                  // handle: {
                  //   crumb: 'Tests',
                  // },
                },
                {
                  path: 'gpaScores',
                  element: (
                    <Suspense fallback={<div>Loading...</div>}>
                      <GPAPageContainer />
                    </Suspense>
                  ),
                  // handle: {
                  //   crumb: 'Tests',
                  // },
                },
                {
                  path: 'courses',
                  element: (
                    <Suspense fallback={<div>Loading...</div>}>
                      <CoursesPageContainer />
                    </Suspense>
                  ),
                  // handle: {
                  //   crumb: 'Courses',
                  // },
                },
              ],
            },
            {
              path: 'all-activities',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <AllActivitiesPageContainer />
                </Suspense>
              ),
            },
            {
              path: 'myHome',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <MyHomePageContainer />
                </Suspense>
              ),
            },
            {
              path: 'all-honors',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <AllHonorsPageContainer />
                </Suspense>
              ),
            },
            {
              path: 'myEssay',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <LicenseGuard>
                    <MyEssayPageContainer />
                  </LicenseGuard>
                </Suspense>
              ),
              handle: {
                crumb: 'My Essays',
              },
            },
            {
              path: 'myColleges',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <LicenseGuard>
                    <MyApplicationsPageContainer />
                  </LicenseGuard>
                </Suspense>
              ),
              handle: {
                crumb: 'My Colleges',
              },
            },
            {
              path: 'referAFriend',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ReferAFriendPageContainer />
                </Suspense>
              ),
              handle: {
                crumb: 'Refer a Friend',
              },
            },
            {
              path: 'myTeam',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <MyTeamPageContainer />
                </Suspense>
              ),
              handle: {
                crumb: 'My Team',
              },
            },
            {
              path: 'myStudents',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <MyStudentPageContainer />
                </Suspense>
              ),
              handle: {
                crumb: 'My Students',
              },
            },
            {
              path: 'myHub',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <LicenseGuard>
                    <MyHubContainer />
                  </LicenseGuard>
                </Suspense>
              ),
              handle: {
                crumb: 'My Hub',
              },
            },
            {
              path: 'userLoginDetail',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <UserLoginDetailsContainer />
                </Suspense>
              ),
              handle: {
                crumb: 'User Login Details',
              },
            },
            {
              path: 'colleges',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <CollegeDetailsPageContainer />
                </Suspense>
              ),
              handle: {
                crumb: 'Colleges',
              },
            },
            {
              path: 'universilyTemplates',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <UniversilyTemplatesContainer />
                </Suspense>
              ),
              handle: {
                crumb: 'Universily Templates',
              },
            },
            {
              path: 'commonEssays',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <CommonEssayContainer />
                </Suspense>
              ),
              handle: {
                crumb: 'Common Essays',
              },
            },

            {
              path: 'myAccount',
              //element: user ? (
              //  <MyAccountPageContainer selectedTab={0} />
              //) : (
              //  <Navigate to={ROUTES.SIGN_IN} />
              //),
              handle: {
                crumb: 'My Account',
              },
              children: [
                {
                  path: 'account',
                  element: (
                    <Suspense fallback={<div>Loading...</div>}>
                      <MyAccountPageContainer selectedTab={0} />
                    </Suspense>
                  ),
                },
                {
                  path: 'subscriptions',
                  element: (
                    <Suspense fallback={<div>Loading...</div>}>
                      <MyAccountPageContainer selectedTab={1} />
                    </Suspense>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/action',
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <CustomActionURLContainer />
        </Suspense>
      ),
    },
    {
      path: '/*',
      element: !user ? (
        <Navigate to={ROUTES.SIGN_IN} />
      ) : (
        <Navigate to={ROUTES.MY_STORY} />
      ),
    },
  ]);
};
