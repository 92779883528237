import {
  AccountIcon,
  ApplicationIcon,
  EssaysIcon,
  HomeSidebarIcon,
  StoryIcon,
  TeamIcon,
} from '../assets';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import * as ROUTES from '../navigation/constants';
import SVG from 'react-inlinesvg';
import HomeIcon from '@mui/icons-material/Home';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { ReferAFriendIcon } from '../assets/svgs/ReferAFriendIcon';
import SchoolIcon from '@mui/icons-material/School';
import LogoutIcon from '@mui/icons-material/Logout';
import FileCopyIcon from '@mui/icons-material/FileCopy';
// SIDEBAR ITEMS

export const sideBarItems = [
  {
    id: 0,
    label: 'My Hub',
    path: ROUTES.MY_HUB,
    icon: (className) => <DashboardIcon className={className} />,
  },
  {
    id: 1,
    label: 'My Story',
    path: ROUTES.MY_STORY,
    icon: (className) => <ViewCarouselIcon className={className} />,
  },
  {
    id: 2,
    label: 'My Essays',
    path: ROUTES.MY_ESSAY,
    icon: (className) => <AssignmentIcon className={className} />,
  },
  {
    id: 3,
    label: 'My Colleges',
    path: ROUTES.MY_COLLEGES,
    icon: (className) => <SchoolIcon className={className} />,
  },
];

export const sideBarBottomNavItems = [
  {
    id: 5,
    label: 'Refer a friend',
    path: ROUTES.INVITE_FRIEND,
    icon: () => <SVG src={ReferAFriendIcon} />,
  },
  {
    id: 4,
    label: 'My Team',
    path: ROUTES.MY_TEAM,
    icon: (className) => <GroupsIcon className={className} />,
  },
  {
    id: 6,
    label: 'My Account',
    path: ROUTES.MY_ACCOUNT,
    icon: (className) => <PersonIcon className={className} />,
  },
  {
    id: 7,
    label: 'Logout',
    path: '',
    icon: (className) => <LogoutIcon className={className} />,
  },
];

export const adminSideBarBottomNavItems = [
  {
    id: 177,
    label: 'My Account',
    path: ROUTES.MY_ACCOUNT,
    icon: (className) => <PersonIcon className={className} />,
  },
  {
    id: 178,
    label: 'Logout',
    path: '',
    icon: (className) => <LogoutIcon className={className} />,
  },
];

// TAB ITEMS
export const myStoryTabItems = [
  {
    id: 0,
    label: 'Niche',
    value: 'niche',
    path: '/myStory/niche',
    count: 0,
  },
  {
    id: 1,
    label: 'Activities',
    value: 'activities',
    path: '/myStory/activities',
    count: 0,
  },
  {
    id: 2,
    label: 'Honors',
    value: 'honors',
    path: '/myStory/honors',
    count: 0,
  },
  {
    id: 3,
    label: 'Courses',
    value: 'courses',
    path: '/myStory/courses',
    count: 0,
  },
  {
    id: 4,
    label: 'Tests',
    value: 'tests',
    path: '/myStory/tests',
    count: 0,
  },
];

// DROPDOWN ITEMS
export const myStoryAddOptions = [
  {
    id: 0,
    label: 'Niche',
    value: 'niche',
  },
  {
    id: 1,
    label: 'Activity',
    value: 'activity',
  },
  {
    id: 2,
    label: 'Honor',
    value: 'honor',
  },
  {
    id: 3,
    label: 'Course',
    value: 'course',
  },
  {
    id: 4,
    label: 'Test',
    value: 'test',
  },
];

export const courseMenuItems = [
  {
    id: 0,
    label: 'Edit Course',
    value: 'edit',
  },
  {
    id: 1,
    label: 'Copy Course',
    value: 'copy',
  },
  {
    id: 2,
    label: 'Delete Course',
    value: 'delete',
  },
];

// MISC ITEMS
export const addStoryItemsForModal = [
  {
    id: 'niche',
    label: 'Niche',
    icon: '../assets/images/niche-modal-icon.svg',
  },
  {
    id: 'activity',
    label: 'Activity',
    icon: '../assets/images/activity-modal-icon.svg',
  },
  {
    id: 'honor',
    label: 'Honor',
    icon: '../assets/images/honour-modal-icon.svg',
  },
  {
    id: 'course',
    label: 'Course',
    icon: '../assets/images/course-modal-icon.svg',
  },
  {
    id: 'test',
    label: 'Test',
    icon: '../assets/images/test-modal-icon.svg',
  },
];

export const courseYearItems = [
  {
    id: 0,
    value: '9',
    label: '9th',
  },
  {
    id: 1,
    value: '10',
    label: '10th',
  },
  {
    id: 2,
    value: '11',
    label: '11th',
  },
  {
    id: 3,
    value: '12',
    label: '12th',
  },
  {
    id: 4,
    value: '12+',
    label: '12th +',
  },
];

export const logInProviders = {
  EMAIL_PASS: 'emailPassword',
  GOOGLE: 'google',
};

export const adminDashboard = [
  {
    id: 'userLoginDetails',
    path: ROUTES.USER_LOGIN_DETAILS,
    label: 'User Login Details',
    icon: (className) => <AccountBoxIcon className={className} />,
  },
  {
    id: 'colleges',
    path: ROUTES.COLLEGES,
    label: 'Colleges',
    icon: (className) => <SchoolIcon className={className} />,
  },
  {
    id: 'universilyTemplates',
    path: ROUTES.UNIVERSILY_TEMPLATES,
    label: 'Universily Templates',
    icon: (className) => <AssignmentIcon className={className} />,
  },
  {
    id: 'commonEssays',
    path: ROUTES.COMMON_ESSAYS,
    label: 'Common Essays',
    icon: (className) => <FileCopyIcon className={className} />,
  },
];

export const freemiumLimitCounts = {
  ACTIVITY: 5,
  ESSAY: 1,
  COURSE: 5,
  HONOR: 2,
  APPLICATION: 3,
};

export const myStoryUtilities = {
  ACTIVITY: 'Activities',
  COURSE: 'Courses',
  HONOR: 'Honors',
};

export const customActionURL = {
  resetPassword: 'resetPassword',
  verifyEmail: 'verifyEmail',
  recoverEmail: 'recoverEmail',
};

export const teamUniversilyEmail = 'team@universily.com';
export const ASK_UNI_MAIL = 'askuni@universily.com';
export const AI_INIT_TEXT = 'Hey, Uni';

export const coreEssayTypes = [
  'Personal Essay',
  'Community Essay',
  'Academic Essay',
  'Why Here',
  'Argument Essay',
  'Additional Information',
  'Extracurricular Essay',
];

export const googleLogin = 'google';
export const unassignedNiche = 'Unassigned';
export const essayType = {
  core: 'Core',
  common: 'Common',
  notDefined: 'Not Defined',
};

export const applicationPlatforms = {
  COMMON_APP: 'Common App Essay',
  COALITION_APP: 'Coalition App Essays',
};
export const activityDocCharVersion = {
  CHAR_150: '150 Characters',
  CHAR_350: '350 Characters',
};

export const honorDocCharVersion = {
  CHAR_100: '100 Characters',
  CHAR_250: '250 Characters',
};

export const applicationEssayType = {
  applicationPlatform: 'applicationPlatform',
  college: 'college',
};
export const essayTypeForPlatform = {
  coreEssay: 'coreEssay',
  applicationEssay: 'applicationEssay',
};
export const essaySubTypeApplicationEssay = {
  college: 'college',
  application: 'application',
};

export const applicationPlatformsList = [
  {
    id: 0,
    value: 'state-university-of-new-york',
    label: 'State University of New York',
  },
  {
    id: 1,
    value: 'apply-texas',
    label: 'Apply Texas',
  },
  {
    id: 2,
    value: 'california-state-university',
    label: 'California State University',
  },
  {
    id: 3,
    value: 'coalition-for-college',
    label: 'Coalition for College',
  },
  {
    id: 4,
    value: 'college-website',
    label: 'College Website',
  },
  {
    id: 5,
    value: 'common-app',
    label: 'Common App',
  },
  {
    id: 6,
    value: 'slideRoom',
    label: 'Slide Room',
  },
  {
    id: 7,
    value: 'university-of-ca-admissions',
    label: 'University of CA Admissions',
  },
  {
    id: 8,
    value: 'university-of-california',
    label: 'University of California',
  },
];

export const googleAuthTypes = {
  TOKEN_EXCHANGE: 'exchange', // default
  TOKEN_REFRESH: 'tokenRefresh',
};

export const acceptedStatusLabel = 'Accepted';

export const PROMPT_TYPES = {
  general: [],
  myHub: [
    {
      id: 'AI-Prompt-myHub-Start',
      label: 'How do I get started?',
      value: `How do I get started?`,
    },
  ],
  niche: [
    {
      id: 'AI-Prompt-Niche-Start',
      label: 'How to get started with Niches?',
      value: `How do I get started with Niches?  Based on this list of what I have done so far, what should I do next?

      Activities: ACTIVITIES;
      Honors: HONORS;
      Courses: COURSES;
      Niches: NICHES;`,
    },
    {
      id: 'AI-Prompt-Suggest-Niche',
      label: 'Help me find my Niche',
      value: `
  
    Here is my story.
  
    Activities: ACTIVITIES;
    Honors: HONORS;
    Courses: COURSES;`,
    },
  ],
  activity: [
    {
      id: 'AI-Prompt-Suggest-Activities',
      label: 'Help me find Activities that support my Niche',
      value: `Please help me find Activities that support my Niche.

      Here are my Niches: NICHES;`,
    },
    {
      id: 'AI-Prompt-Suggest-Nearby-Activities',
      label: 'Help me find Activities near me',
      value: `Help me find Activities online or near: MAILING_ADDRESS.
      Here are my niches: NICHES.
      `,
    },
  ],
  honor: [
    {
      id: 'AI-Prompt-Suggest-Honors',
      label: 'Help me find Honors or Awards that support my Niche',
      value: `Please help me find high school student Honors or Awards that support my Niche.

      Here are my Niches: NICHES;`,
    },
  ],
  course: [
    {
      id: 'AI-Prompt-Suggest-Courses',
      label: 'Help me find Courses that support my Niche',
      value: `Please help me find online or local Courses that support my Niche. 
      
      Here are my niches: NICHES;`,
    },
  ],
  essay: [
    {
      id: 'AI-Prompt-Essay-Start',
      label: 'How to get started with Essays?',
      value: `How do I get started with core Essays related to this list of my Niches?

      Niches: NICHES;`,
    },
  ],
  essayDetail: [
    {
      id: 'AI-Prompt-Essay-Brainstorm',
      label: 'Help me brainstorm topics for this Essay',
      value: ` 
      Here are the Essay Details delimited in """:
      """
      Essay Name: essayName;
      Essay Prompt: essayPrompt;
      Essay Word Limit: essayWordLimit;
      """
      `,
    },
    {
      id: 'AI-Prompt-Essay-Outline',
      label: 'Help me create an outline for this Essay',
      value: `
      Here are the Essay Details delimited in """:
      """
      Essay Name: essayName;
      Essay Prompt: essayPrompt;
      Essay Word Limit: essayWordLimit;
      Essay Content: essayContent
      """
      `,
    },
    {
      id: 'AI-Prompt-Essay-Review',
      label: 'Please review this Essay',
      value: `
      Here are the Essay Details delimited in """:
      """
      Essay Name: essayName;
      Essay Prompt: essayPrompt;
      Essay Word Limit: essayWordLimit;
      Essay Content: essayContent;
    """
      `,
    },
  ],
  application: [
    {
      id: 'AI-Prompt-Application-Start',
      label: 'How to get started with Applications?',
      value: `Please help me get started with using My Applications.`,
    },
    {
      id: 'AI-Prompt-Suggest-Colleges',
      label: 'Which colleges might be good for me?',
      value: `who lives at: MAILING_ADDRESS.

      Here is My Story:

      Areas of Interest: NICHES;
      Activities: ACTIVITIES;
      Honors: HONORS;
      Courses: COURSES;
      Tests: SAT SAT_SCORE; ACT ACT_SCORE;

      Based on My Story information, can you suggest some colleges that would be a good fit for my Areas of Interest?`,
    },
  ],
  // applicationDetail: [
  //   {
  //     id: 'AI-Prompt-Application-ChanceMe',
  //     label: `"Chance Me" for this college!`,
  //     value: `
  //     I am particularly interested in COLLEGE_NAME. Based on my profile, could you please provide feedback on my chances of being admitted? Any insights or advice would be greatly appreciated. Thank you!`,
  //   },
  // ],
};
