const getEnvironment = () => {
  switch (process.env.REACT_APP_PROJECT_ENV) {
    case 'production':
      return 'production';
    case 'development':
      return 'development';
    case 'v2Development':
      return 'v2Development';
    case 'v2Production':
      return 'v2Production';
    default:
      return '';
  }
};
const getBaseUrl = () => {
  switch (process.env.REACT_APP_PROJECT_ENV) {
    case 'production':
      return 'https://app.universily.com/myStory';
    case 'development':
      return 'https://universilyv2.vercel.app/myStory';
    case 'v2Development':
      return 'https://v2dev.universily.com/myStory';
    case 'v2Production':
      return 'https://app.universily.com/myStory';
    default:
      return 'http://localhost:3000/myStory';
  }
};
const getStripeRedirectUrl = () => {
  switch (process.env.REACT_APP_PROJECT_ENV) {
    case 'production':
      return 'https://app.universily.com/account/subscription';
    case 'development':
      return 'https://universilyv2.vercel.app/myAccount/subscriptions';
    case 'v2Development':
      return 'https://v2dev.universily.com/myAccount/subscriptions';
    case 'v2Production':
      return 'https://app.universily.com/myAccount/subscriptions';
    default:
      return 'http://localhost:3000/myAccount/subscriptions';
  }
};
const getStripeRedirectUrlParents = () => {
  switch (process.env.REACT_APP_PROJECT_ENV) {
    case 'production':
      return 'https://app.universily.com/account/subscription';
    case 'development':
      return 'https://universilyv2.vercel.app/myAccount/subscriptions';
    case 'v2Development':
      return 'https://v2dev.universily.com/myStudents';
    case 'v2Production':
      return 'https://app.universily.com/myStudents';
    default:
      return 'http://localhost:3000/myStudents';
  }
};
const getSiteBaseUrl = () => {
  switch (process.env.REACT_APP_PROJECT_ENV) {
    case 'production':
      return 'https://app.universily.com';
    case 'development':
      return 'https://universilyv2.vercel.app';
    case 'v2Development':
      return 'https://v2dev.universily.com';
    case 'v2Production':
      return 'https://app.universily.com';
    default:
      return 'http://localhost:3000';
  }
};
const getUniversilyBackendBaseUrl = () => {
  switch (process.env.REACT_APP_PROJECT_ENV) {
    case 'production':
      return `https://us-central1-universily-net.cloudfunctions.net`;
    case 'development':
      return `https://us-central1-universily-v2-dev.cloudfunctions.net`;
    case 'v2Development':
      return `https://us-central1-universily-v2-dev.cloudfunctions.net`;
    case 'v2Production':
      return 'https://us-central1-universily-v2-prod.cloudfunctions.net';
    default:
      return `https://us-central1-universily-v2-dev.cloudfunctions.net`;
  }
};
const getStripeSubscriptionPriceId = () => {
  switch (process.env.REACT_APP_PROJECT_ENV) {
    case 'v2Production':
      return {
        monthPlan: `price_1PC5MqLEIgs8dio2oL48juVq`, // STRIPE CLIENT'S ACC LIVE MODE
        yearPlan: `price_1PC5PBLEIgs8dio21lW7jTgP`, // STRIPE CLIENT'S ACC LIVE MODE
        unlimitedPlan: `price_1PC5SFLEIgs8dio2ZWQF3u1H`, // STRIPE CLIENT'S ACC LIVE MODE
      };
    case 'v2Development':
      return {
        monthPlan: `price_1PC4QELEIgs8dio2twMM7pb3`, // STRIPE CLIENT'S ACC TEST MODE
        yearPlan: `price_1PC4vyLEIgs8dio216qszEbn`, // STRIPE CLIENT'S ACC TEST MODE
        unlimitedPlan: `price_1PC4zALEIgs8dio2gnMugLLB`, // STRIPE CLIENT'S ACC TEST MODE
      };
    default:
      return {
        monthPlan: `price_1M5LtZLEIgs8dio2EIc6oqJM`, // STRIPE CLIENT'S ACC TEST MODE
        yearPlan: `price_1M5LtZLEIgs8dio2HFkT43xp`, // STRIPE CLIENT'S ACC TEST MODE
      };
  }
};
const Url = {
  baseUrl: getBaseUrl(),
  baseSiteUrl: getSiteBaseUrl(),
  stripeRedirectUrl: getStripeRedirectUrl(),
  stripeRedirectUrlParents: getStripeRedirectUrlParents(),
  backendUrl: getUniversilyBackendBaseUrl(),
};
const config = {
  BASE_URL: `${Url.baseUrl}`,
  environment: getEnvironment(),
  getBaseUrl: getBaseUrl(),
  getSiteBaseUrl: Url.baseSiteUrl,
  getStripeRedirectUrl: Url.stripeRedirectUrl,
  getStripeRedirectUrlParents: Url.stripeRedirectUrlParents,
  getStripeSubscriptionPriceId: getStripeSubscriptionPriceId(),
  backendUrl: Url.backendUrl,
};
export default config;
