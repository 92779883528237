import { create } from 'zustand';

const useProfileStore = create((set) => ({
  profile: {},
  setProfile: (profile) => set(() => ({ profile })),
  isParent: false,
  isSubscriptionActive: false,
  setIsSubscriptionActive: (isSubscriptionActive) =>
    set(() => ({ isSubscriptionActive })),
  profileLogin: {},
  setProfileLogin: (profileLogin) => set(() => ({ profileLogin })),
  subscriptions: [],
  setSubscriptions: (subscriptions) => set(() => ({ subscriptions })),
  isEditingProfile: false,
  setIsEditingProfile: (isEditingProfile) => set(() => ({ isEditingProfile })),
  editProfileId: null,
  setEditProfileId: (editProfileId) => set(() => ({ editProfileId })),
  isChangePassword: false,
  setIsChangePassword: (isChangePassword) => set(() => ({ isChangePassword })),
  snackBarStatus: {
    open: false,
    message: '',
  },
  setSnackBarStatus: (snackBarStatus) => set(() => ({ snackBarStatus })),
  setIsParent: (isParent) => set(() => ({ isParent })),
}));

export default useProfileStore;
