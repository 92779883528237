import { useState, useEffect } from 'react';

import { firebaseDataType } from '../constants/other';
import { listenCollectionData } from '../firebase/services/listeners';
import useProfileStore from '../stores/ProfileStore';

// TODO EXAMPLE CALL
// const { user } = useAuth();

// const [data, isLoading] = useStoryItemListener(
//   REVIEWER,
//   firebaseDataType.STUDENT_DATA,
//   [
//     {
//       property: 'createdBy',
//       operator: firebaseQueryOperators.EQUAL_TO,
//       value: user?.uid,
//     },
//     {
//       property: 'deleteDocument',
//       operator: firebaseQueryOperators.EQUAL_TO,
//       value: false,
//     },
//   ]
// );

const useStoryItemListener = (
  collectionName,
  dataType = firebaseDataType.STUDENT_DATA,
  queryList = null,
  userId
) => {
  const [collectionData, setCollectionData] = useState(null);
  const [isDataFetching, setIsDataFetching] = useState(true);
  const { profile } = useProfileStore();

  const nullQuery =
    (queryList &&
      queryList.find(
        (query) => query.value === undefined || query.value === null
      )) ||
    collectionName.includes('undefined') ||
    collectionName === '';

  useEffect(() => {
    if (!nullQuery && profile?.uid) {
      const listener = listenCollectionData(
        collectionName,
        (fetchedData) => {
          setCollectionData(fetchedData);
          setIsDataFetching(false);
        },
        dataType,
        nullQuery ? null : queryList
      );
      return () => listener && listener();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nullQuery, userId, profile?.uid]);

  return {
    data: collectionData,
    isFetching: userId ? isDataFetching : false,
    dataCount: collectionData ? collectionData.length : 0,
  };
};

export default useStoryItemListener;
