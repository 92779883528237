import create from 'zustand';

const useApplicationStore = create((set) => ({
  isAddCollege: false,
  setIsAddCollege: (isAddCollege) => set(() => ({ isAddCollege })),
  applications: [],
  applicationsById: {},
  setApplications: (applications) =>
    set(() => ({
      applications,
      applicationsById: applications?.reduce(
        (acc, application) => ({
          ...acc,
          [application.id]: application,
        }),
        {}
      ),
    })),
  isCollegeId: null,
  setIsCollegeId: (isCollegeId) => set(() => ({ isCollegeId })),
  isChangeRound: false,
  setIsChangeRound: (isChangeRound) => set(() => ({ isChangeRound })),
  isRoundId: null,
  setIsRoundId: (isRoundId) => set(() => ({ isRoundId })),
  isAddCollegeApplication: false,
  setIsAddCollegeApplication: (isAddCollegeApplication) =>
    set(() => ({ isAddCollegeApplication })),
  isAddApplicationRequirement: false,
  setIsAddApplicationRequirement: (isAddApplicationRequirement) =>
    set(() => ({ isAddApplicationRequirement })),
  isCopyDetailsFromMyStory: false,
  setIsCopyDetailsFromMyStory: (isCopyDetailsFromMyStory) =>
    set(() => ({ isCopyDetailsFromMyStory })),
  isDeletingCollege: false,
  setIsDeletingCollege: (isDeletingCollege) =>
    set(() => ({ isDeletingCollege })),
  deleteCollegeId: null,
  setDeleteCollegeId: (deleteCollegeId) =>
    set(() => ({
      deleteCollegeId,
    })),
  showDeleteSnackbar: false,
  setShowDeleteSnackbar: (showDeleteSnackbar) =>
    set(() => ({ showDeleteSnackbar })),
  roundsTab: false,
  setRoundsTab: (roundsTab) => set(() => ({ roundsTab })),
  changeStatusToAccepted: false,
  setChangeStatusToAccepted: (changeStatusToAccepted) =>
    set(() => ({ changeStatusToAccepted })),
  markTasksAsSubmitted: false,
  setMarkTasksAsSubmitted: (markTasksAsSubmitted) =>
    set(() => ({ markTasksAsSubmitted })),
  isAddingEssay: false,
  setIsAddingEssay: (isAddingEssay) => set(() => ({ isAddingEssay })),
}));

export default useApplicationStore;
