import { ThemeProvider } from '@mui/material/styles';
import { customTheme } from './styles/customTheme';
import { CustomGlobalStyles } from './styles/globalStyles';
import { NotiStackProvider } from './components';
import RouterWrapper from './RouterWrapper';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UserProfileContextProvider } from './contexts/userContext';
import useThemeStore from './stores/ThemeStore';
import userflow from 'userflow.js';
import useProfileStore from './stores/ProfileStore';
import { sha256 } from 'js-sha256';

const App = () => {
  const theme = useTheme();
  const { setIsMobile } = useThemeStore();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const profileStore = useProfileStore();
  useEffect(() => {
    setIsMobile(isMobile);
    //eslint-disable-next-line
  }, [isMobile]);
  useEffect(() => {
    const profile = profileStore?.profile;

    if (profile?.uid) {
      const UF_SECRET = process.env.REACT_APP_USERFLOW_TOKEN;
      const UF_IDENTITIY_VERIFICATION_KEY =
        process.env.REACT_APP_USERFLOW_IDENTITY_VERIFICATION_KEY;
      let signature = sha256.hmac(UF_IDENTITIY_VERIFICATION_KEY, profile?.uid);

      const timestamp = profile?.createdAt?.seconds * 1000;
      const date = new Date(timestamp);
      const signed_up = date.toISOString();
      userflow.init(UF_SECRET);
      userflow.identify(
        profile?.uid,
        {
          name: profile?.firstName,
          email: profile?.email,
          signed_up_at: signed_up,
          profileType: profile?.profileType,
          grade: profile?.grade,
          subscriptionStatus: profile?.licenseData?.subscriptionStatus,
        },
        {
          signature: signature,
        }
      );
    }
    //eslint-disable-next-line
  }, [profileStore?.profile]);

  return (
    <UserProfileContextProvider>
      <ThemeProvider theme={customTheme}>
        <NotiStackProvider>
          <CustomGlobalStyles />
          <RouterWrapper />
        </NotiStackProvider>
      </ThemeProvider>
    </UserProfileContextProvider>
  );
};

export default App;
